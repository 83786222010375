import { DOWNLOAD_CENTER_SERVICE_URL } from '@/constants'
import type { DownloadableFile } from '@/types'

export default function generateDownloadURL(
  file: DownloadableFile,
  country: string | null,
): string {
  const countryQueryParam = country ? `&country=${country.toLowerCase()}` : ''

  return `${DOWNLOAD_CENTER_SERVICE_URL}/assets/${file.id}?download=true${countryQueryParam}`
}
